import { ReactNode } from "react";
import styles from "./Loading.module.css";

export default function Loading(props: {
  isLoading: boolean;
  children: ReactNode;
}) {
  if (props.isLoading) {
    return (
      <>
        <div className={styles["lds-facebook"]}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </>
    );
  } else {
    return <>{props.children}</>;
  }
}
