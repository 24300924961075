import { ReactElement, useEffect, useRef, useState } from "react";
import styles from "./HamburgerIcon.module.css";

export default function HamburgerIcon(props: {
  size: string;
  onToggle: (isOpen: boolean) => void;
  value: boolean;
}): ReactElement {
  const buttonRef = useRef<HTMLButtonElement>(null);

  function toggleHandler() {
    props.onToggle(!props.value);
  }

  useEffect(() => {
    buttonRef.current?.style.setProperty("--size", props.size);
  }, [buttonRef, props.size]);

  const className = props.value
    ? `${styles.button} ${styles.open}`
    : `${styles.button}`;

  return (
    <>
      <button ref={buttonRef} className={className} onClick={toggleHandler}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </>
  );
}
