import { MouseEvent, ReactElement, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import NotificationsContext from "../../store/notifications-context";
import Loading from "../Loading";
import LoremIpsum from "../LoremIpsum";
import ModalOverlay from "../ModalOverlay";

export default function LoremIpsumPage(): ReactElement {
  const notificationsContext = useContext(NotificationsContext);

  const [smallModalOpened, setSmallModalOpened] = useState(false);
  const [bigModalOpened, setBigModalOpened] = useState(false);

  function addNotificationClickHandler(event: MouseEvent<HTMLElement>) {
    notificationsContext.addNotification({
      message: "You added a notification :)",
    });
  }

  function smallModalOpenClickHandler(event: MouseEvent<HTMLElement>) {
    setSmallModalOpened(true);
  }

  function smallModalCloseClickHandler() {
    setSmallModalOpened(false);
  }

  function bigModalOpenClickHandler(event: MouseEvent<HTMLElement>) {
    setBigModalOpened(true);
  }

  function bigModalCloseClickHandler() {
    setBigModalOpened(false);
  }

  return (
    <>
      <ModalOverlay isShown={bigModalOpened}>
        <button className="button button-primary" onClick={bigModalCloseClickHandler}>
          Close
        </button>
        <span>This should be in the middle of the screen</span>
        <LoremIpsum />
      </ModalOverlay>

      <ModalOverlay isShown={smallModalOpened}>
        <button className="button button-primary" onClick={smallModalCloseClickHandler}>
          Close
        </button>
        <span>This should be in the middle of the screen</span>
      </ModalOverlay>

      <div>
        <Loading isLoading>Lorem ipsum</Loading>
      </div>

      <div className="d-flex flex-column flex-gap-1em m-2">
        <div className="d-flex flex-row flex-wrap flex-gap-1em">
          <button className="button button-primary" onClick={addNotificationClickHandler}>
            Add a notification
          </button>
          <button className="button button-primary" onClick={addNotificationClickHandler}>
            Add a notification
          </button>
          <button className="button button-primary" disabled>
            Disabled button
          </button>

          <NavLink className="button button-primary" to="/lorem-ipsum">
            Lorem ipsum
          </NavLink>
        </div>

        <button className="button button-primary" onClick={smallModalOpenClickHandler}>
          Open up small modal
        </button>
        <button className="button button-primary" onClick={bigModalOpenClickHandler}>
          Open up big modal
        </button>

        <NavLink className="button button-primary" to="/">
          Home
        </NavLink>

        <LoremIpsum />
      </div>
    </>
  );
}
