import { ReactElement } from "react";
import { NavLink } from "react-router-dom";

export default function HomePage(): ReactElement {
  return (
    <>
      <p>This is my home</p>
      Navigation to the <NavLink to="/lorem-ipsum">Lorem ipsum page</NavLink> takes no time, because everything is already fetched from the server
    </>
  );
}
