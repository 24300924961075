import { ReactElement, ReactNode, useState } from "react";
import styles from "./Sidebar.module.css";
import HamburgerIcon from "./icons/HamburgerIcon";

export default function Sidebar(props: {
  sidebarContent: ReactNode;
  children: ReactNode;
  onSetMinimized: (isMinimized: boolean) => void;
}): ReactElement {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  function toggleSidebar(isOpened: boolean) {
    setIsSidebarOpen(isOpened);
    props.onSetMinimized(!isOpened);
  }

  const sidebarOpenClass = isSidebarOpen ? styles.open : "";

  return (
    <>
      <div id={styles.sidebar_container}>
        <div id={styles.sidebar_content} className={`${sidebarOpenClass}`}>
          <div className={`${styles.hamburger_icon}`}>
            <HamburgerIcon size="2rem" onToggle={toggleSidebar} value={isSidebarOpen} />
          </div>
          {props.sidebarContent}
        </div>
        <div id={styles.main_content}>{props.children}</div>
      </div>
    </>
  );

  // return (
  //   <>
  //     <div id={styles.sidebar_container}>
  //       <div id={styles.sidebar_content} className={`${sidebarOpenClass}`}>
  //         <div>
  //           <HamburgerIcon size="2rem" onToggle={toggleSidebar} value={isSidebarOpen} />
  //         </div>
  //         {props.sidebarContent}
  //       </div>
  //       <div id={styles.main_content}>{props.children}</div>
  //     </div>
  //   </>
  // );
}

// import { MouseEvent, ReactElement, ReactNode, useRef } from "react";
// import styles from "./Sidebar.module.css";

// export default function Sidebar(props: {
//   collapsedTitle: string,
//   children: ReactNode,
// }): ReactElement {
//   let sidebarRef = useRef<HTMLDivElement>(null);
//   let sidebarOpenRef = useRef<HTMLDivElement>(null);

//   function sidebarOpenClickHandler(e: MouseEvent<HTMLElement>) {
//     sidebarOpenRef.current?.classList.add("hidden");
//     sidebarRef.current?.classList.add("open");
//   }

//   function sidebarCloseClickHandler(e: MouseEvent<HTMLElement>) {
//     sidebarRef.current?.classList.remove("open");
//     sidebarOpenRef.current?.classList.remove("hidden");
//   }

//   return (
//     <>
//       <div ref={sidebarOpenRef} className="sidebar__open" onClick={sidebarOpenClickHandler}>
//         <span>{props.collapsedTitle}</span>
//         <i className="fas fa-chevron-down"></i>
//       </div>
//       <div ref={sidebarRef} className="sidebar">
//         <div className="sidebar__close" onClick={sidebarCloseClickHandler}>
//           <div className="btn narrow danger">
//             Close <i className="fas fa-times"></i>
//           </div>
//         </div>

//         <div className={styles.sidebar_container}>
//           {props.children}
//         </div>
//       </div>
//     </>
//   );
// }
