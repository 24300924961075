import { ReactElement, ReactNode } from "react";
import styles from "./ModalOverlay.module.css";

export default function ModalOverlay(props: {
  isShown: boolean,
  children: ReactNode;
}): ReactElement {
  if (props.isShown) {
    return (
      <>
        <div className={styles.backdrop}>
          <div className={styles.content}>
            {props.children}
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
}
