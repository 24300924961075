import { ReactElement } from "react";
import { NavLink } from "react-router-dom";

export default function NavigationItems(props: {
  getNavLinkClass(isActive: boolean): string;
}): ReactElement {
  const containerGetNavLinkClass = props.getNavLinkClass;

  function getNavLinkClass(props: { isActive: boolean }): string {
    return containerGetNavLinkClass(props.isActive);
  }

  return (
    <>
      <NavLink className={getNavLinkClass} to="/">
        Home
      </NavLink>
      <NavLink className={getNavLinkClass} to="/lorem-ipsum">
        Lorem Ipsum
      </NavLink>
    </>
  );
}
