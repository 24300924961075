import { ReactElement, ReactNode, useState } from "react";
import Notifications from "./Notifications";
import styles from "./Layout.module.css";
import NavigationItems from "./NavigationItems";
import HamburgerIcon from "./icons/HamburgerIcon";
import { NavLink } from "react-router-dom";

export default function Layout(props: { children: ReactNode }): ReactElement {
  const [isSmallNavbarOpen, setIsSmallNavbarOpen] = useState(false);

  function getNavLinkClass(isActive: boolean): string {
    const baseClass = `${styles.navbar_item}`;

    if (isActive) {
      return `${baseClass} ${styles.active}`;
    } else {
      return baseClass;
    }
  }

  function hamburgerToggled(isOpened: boolean) {
    setIsSmallNavbarOpen(isOpened);
  }

  const smallNavbarOpenClass = isSmallNavbarOpen ? styles.open : "";

  return (
    <>
      <div className={styles.viewport_overlay}>
        <div className={styles.container}>
          <div className={styles.expanded_header}>
            <nav
              className={`${styles.header_container} d-flex flex-row flex-wrap`}
            >
              <NavLink className="p-3" to="/">
                LOGO
              </NavLink>
              <NavigationItems getNavLinkClass={getNavLinkClass} />
            </nav>
          </div>
          <div className={styles.collapsed_header}>
            <nav className={`${styles.header_container}`}>
              <div className="d-flex flex-row flex-wrap justify-content-between">
                <NavLink className="p-3" to="/">
                  LOGO
                </NavLink>
                <div className="p-2">
                  <HamburgerIcon size="2em" onToggle={hamburgerToggled} value={isSmallNavbarOpen} />
                </div>
              </div>

              <div
                className={`${styles.small_navbar} ${smallNavbarOpenClass} d-flex flex-column flex-stretch`}
              >
                <NavigationItems getNavLinkClass={getNavLinkClass} />
              </div>
            </nav>
          </div>
          <div className={styles.contents_container}>
            <div>{props.children}</div>
          </div>
        </div>
      </div>

      <div id="modal-root" />
      <Notifications />
    </>
  );
}
