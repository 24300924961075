import { ReactElement, useContext } from "react";
import NotificationsContext, { INotification } from "../store/notifications-context";
import Notification from "./Notification";
import styles from "./Notifications.module.css";

export default function Notifications(): ReactElement {
  const notificationsContext = useContext(NotificationsContext);

  return (
    <>
      <div className={styles.notifications_overlay}>
        {notificationsContext.notifications.map((notification: INotification, index) => {
          return <Notification key={index} notification={notification} />;
        })}
      </div>
    </>
  );
}
