import Layout from "./components/Layout";
import styles from "./App.module.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./components/pages/HomePage";
import LoremIpsumPage from "./components/pages/LoremIpsumPage";
import NotFoundPage from "./components/pages/NotFoundPage";
import Sidebar from "./components/Sidebar";
import { useState } from "react";

function App() {
  const [isMinimized, setIsMinimized] = useState(false);

  const colorThemeClass = "light-theme";

  function setMinimized(minimized: boolean) {
    setIsMinimized(minimized);
  }

  return (
    <>
      <div className={styles[colorThemeClass]}>
        <div className={styles.wrapper}>
          <BrowserRouter>
            <Layout>
              <Sidebar
                onSetMinimized={setMinimized}
                sidebarContent={
                  isMinimized ? <>C</> : <>Contents of the sidebar</>
                }
              >
                <Routes>
                  <Route path="/">
                    <Route index element={<HomePage />} />
                    <Route path="lorem-ipsum" element={<LoremIpsumPage />} />
                    <Route path="*" element={<NotFoundPage />} />
                  </Route>
                </Routes>
              </Sidebar>
            </Layout>
          </BrowserRouter>
        </div>
      </div>
    </>
  );
}

export default App;
